* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100vh;
  max-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: light;
  }
}
